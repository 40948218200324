/**
 * Bootstrap 3 jQuery initialization file
 * This file ensures jQuery is properly loaded first, then Bootstrap
 */

// Import jQuery and make it available in the window
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

// Once jQuery is globally available, import Bootstrap
// This ensures Bootstrap can find jQuery in the global scope
export default jQuery; 